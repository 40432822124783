import React, { useState, useEffect } from "react";

//imported module for applying css
import styles from "./my-Style.module.css";
import axios from "axios";

//imported for using more than one class to a single tag
import classNames from "classnames";

//imported for Spinner'
import Spinner from "./Spinner";

//imported for showing toast
import { ToastContainer, toast } from "react-toastify";

// import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

//List of driving lisence states
var dlStates;

//Set containing selectes Areas from different states
var SelectedAreaSet = new Set();

//Map contining service VS Fields
var service_RequiredFieldsMap = new Map();

//Array Containing Names of Selected Services
var selectedServiceNames = [];

//Variable Contining current Selected State
var CurrentState;

//Map of States and Areas Corresponding to those states
var stateVsAreas = new Map();

//Created Variable for removing White spaces from the states coming from callouts
var currentStatevalue;

//Variable for handling Submit button disable enable state
var submitButtonCheckbox = false;

//States for Drving Liscence
var statesForDrivingLicense;

//Education Fields Array
var educationFieldsArray = [];

//Employement Fields Array
var employementFieldArray = [];

//Set for storing the employements checked
var employementCheckedSet = new Set();

//Set for storing the education checked
var educationCheckedSet = new Set();

//Selected Package
var SelectedPackage;
// Employee id

//Number of Education related to selected Package
var numEduSelectedPackage;

//Number of Education related to selected Package
var numEmpSelectedPackage;

// var for Enabling/Disabling the education checkbox
var disableEducation = false;

//For Enabling/Disabling the employement checkbox
var disableEmployement = false;

function App() {
  //Declared  finalStatus & ErrorResponse variables for storing response from the background check
  var finalStatus;
  var ErrorResponse;
  var educationId;
  var errorResponse;
  useEffect(() => {
    // Function to send data to Node.js
    const sendEmpIdToNode = async () => {
      try {
        const queryParams = new URLSearchParams(window.location.search);
        const value = queryParams.get("empId");
        if (value) {
          setLoading(true); // Start loading spinner

          try {
            const message = `${finalStatus} ${ErrorResponse}`;
            if (finalStatus === "ACCEPTED") {
              toast.success(message, toastOptions);
            }

            // Call other functions in parallel
            await Promise.all([
              educationFieldsData(),
              getEmploymentFields(),
              educationCreationData(),
            ]);
          } catch (error) {
            console.error("Error in parallel function calls:", error);
          }

          try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/empId`, { value });
            setLoading(false); // Stop loading spinner

            // Utility function to parse and set maps
            const parseAndSetMap = (data, setter) => {
              const parsedData = JSON.parse(data);
              setter(new Map(parsedData));
            };

            setbackGroundDetails(response.data.backGroundDetails);
            setstatesArray(response.data.statesArray);
            setPackages(response.data.package_array);

            // Handling various response fields
            parseAndSetMap(
              response.data.stringifiedpackageIdDataMap,
              setPackageIdVsDataMap
            );
            parseAndSetMap(
              response.data.stringifiedPackageIdVsFieldsMap,
              setPackageIdFieldsMap
            );
            parseAndSetMap(
              response.data.stringifiedstatesAreaMap,
              setstatesAreaMap
            );

            const parsedEduDetails = JSON.parse(
              response.data.stringifiededucation_DetailsMap
            );
            seteducationIdVsEducationDetails(new Map(parsedEduDetails));

            // Set up default education details
            const eduObj = {
              dateAttendedEnd: "",
              dateAttendedStart: "",
              degreeAwarded: "",
              degreeDate: "",
              educationId: "",
              grade: "",
              institutionAttended: "",
              major: "",
              other: "",
            };
            const newMapEducationAddon = new Map();
            newMapEducationAddon.set("0", eduObj);
            seteduDetailsForEducationCreation(newMapEducationAddon);

            const parsedEmpDetails = JSON.parse(
              response.data.stringfiedworkHistory_DetailsMap
            );
            setWorkHistoryIdVsWorkHistoryDetails(new Map(parsedEmpDetails));
          } catch (error) {
            console.error("Error processing response:", error);
          }
        }
      } catch (error) {
        console.error("Error sending data:", error);
      }
    };

    // Call the function
    sendEmpIdToNode();
  }, []);

  const toastOptions = {
    position: toast.POSITION.TOP_CENTER,
    autoClose: 4000, // milliseconds
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  };

  // Method for toast
  function toastMethod() {
    try {
      const message = `${finalStatus} ${ErrorResponse}`;
      if (finalStatus === "ACCEPTED") {
        toast.success(message, toastOptions);
      } else {
        toast.error(message, toastOptions);
      }
    } catch (error) {
      console.error("Error showing toast notification:", error);
    }
  }

  // Method for Toast for Education Created Or not
  function toastEducation() {
    try {
      if (educationId) {
        toast.success(
          `Education created successfully with EducationId: ${educationId}`,
          toastOptions
        );
      } else {
        toast.error(`Error creating Education: ${errorResponse}`, toastOptions);
      }
    } catch (error) {
      console.error("Error displaying toast notification:", error);
    }
  }

  //Array for education fields
  var educationCreationFieldsArray = [];

  //Array for services Fields along with the services names
  var servicesFieldsArray = [];

  //Used for spinner state
  const [loading, setLoading] = useState(false);

  //Created Set for Unique Areas
  const [newSet, SetNewSet] = useState(new Set());

  //Created Array for Displaying FIELDS on UI
  const [relatedFieldsArray, setFieldsArray] = useState(null);

  //Ceated Array for Showing Areas Corresponding to Selected State
  const [AreaArray, setAreaArray] = useState([]);

  //Created Array of Packages For displaying all packages on UI
  const [package_array, setPackages] = useState([]);

  //Created a map of PackageId and NumberOfEduEmp corresponding to it

  const [packageIdVsDataMap, setPackageIdVsDataMap] = useState({});
  //Array for displaying Services on UI
  const [services_array, setService] = useState([]);

  //Map Containing PackageID and Fields Corresponding
  const [packageIdFieldsMap, setPackageIdFieldsMap] = useState(new Map());

  //Object Containg Data from Callouts
  const [backGroundDetails, setbackGroundDetails] = useState(null);

  //Object with Changes Made through UI
  const [updatedbackGroundDetails, setUpdatedbackGroundDetails] =
    useState(null);
  // Package Selected or not checkbox
  const [isPackageSelected, setIsPackageSelected] = useState(false);
  const [isCheckboxSelected1, setIsCheckboxSelected1] = useState(true);

  //Map Containind State Vs Areas
  const [statesAreaMap, setstatesAreaMap] = useState(new Map());

  //Array containing all the states from callout
  const [statesArray, setstatesArray] = useState(["None"]);

  //Array cointaing Related Fields
  const [relatedFieldsServiceArray, setrelatedFieldsServiceArray] = useState(
    []
  );
  //Map Containing EducationId VS Education Details
  const [educationIdVsEducationDetails, seteducationIdVsEducationDetails] =
    useState(new Map());

  const [eduDetailsForEducationCreation, seteduDetailsForEducationCreation] =
    useState(new Map());

  const [
    WorkHistoryIdVsWorkHistoryDetails,
    setWorkHistoryIdVsWorkHistoryDetails,
  ] = useState(new Map());

  function handleWorkHistory(event) {
    try {
      const selectedEmployementId = event.target.name;
      var selectedEmployeeDetails = document.getElementById(
        selectedEmployementId
      );
      if (event.target.checked) {
        employementCheckedSet.add(event.target.name);
      } else {
        employementCheckedSet.delete(event.target.name);
      }
      const empList = document.querySelectorAll(".employmentCheckbox");

      if (employementCheckedSet.size === Number(numEmpSelectedPackage)) {
        empList.forEach((emp) => {
          if (emp.checked !== true) {
            emp.disabled = true;
          }
        });
      } else {
        empList.forEach((emp) => {
          if (emp.checked !== true) {
            emp.disabled = false;
          }
        });
      }
      if (selectedEmployeeDetails.className.indexOf("Styles.w3show") === -1) {
        selectedEmployeeDetails.className += "Styles.w3show";
      } else {
        selectedEmployeeDetails.className =
          selectedEmployeeDetails.className.replace("Styles.w3show", "");
      }
      setEmpFieldData(event.target.name);
    } catch (error) {
      console.error("An error occurred in handleWorkHistory:", error);
    }
    function setEmpFieldData(selectedEmpId) {
      try {
        var currentSelectedEmployementFields = document.getElementsByClassName(
          selectedEmpId + "fields"
        );

        for (let i = 0; i < currentSelectedEmployementFields.length; i++) {
          if (
            currentSelectedEmployementFields[i].name &&
            currentSelectedEmployementFields[i].name.includes(".")
          ) {
            const selectedFieldName = currentSelectedEmployementFields[i].name;
            const splittedCharFieldNameArray = selectedFieldName.split(".");
            var firstElement = splittedCharFieldNameArray[0];
            var secondElement = splittedCharFieldNameArray[1];

            var employerAdress1 =
              WorkHistoryIdVsWorkHistoryDetails.get(selectedEmpId)[
                firstElement
              ];

            if (currentSelectedEmployementFields[i].value !== undefined) {
              currentSelectedEmployementFields[i].value =
                employerAdress1[secondElement];
            }
          } else {
            if (
              WorkHistoryIdVsWorkHistoryDetails.get(selectedEmpId)[
                currentSelectedEmployementFields[i].name
              ] !== undefined
            ) {
              if (
                currentSelectedEmployementFields[i].name === "startDate" ||
                currentSelectedEmployementFields[i].name === "endDate"
              ) {
                const dateTimeString =
                  WorkHistoryIdVsWorkHistoryDetails.get(selectedEmpId)[
                    currentSelectedEmployementFields[i].name
                  ];
                if (dateTimeString !== null) {
                  const result = dateTimeString.split("T")[0];
                  currentSelectedEmployementFields[i].value = result;
                }
              } else {
                currentSelectedEmployementFields[i].value =
                  WorkHistoryIdVsWorkHistoryDetails.get(selectedEmpId)[
                    currentSelectedEmployementFields[i].name
                  ];
              }
            }
          }
        }
      } catch (error) {
        console.error("Error in setEmpFieldData:", error);
      }
    }
  }

  //onClick event for handling the EducationSelection
  function handleEducation(event) {
    try {
      const selectedEducationId = event.target.name;
      if (event.target.name !== "0") {
        if (event.target.checked) {
          educationCheckedSet.add(selectedEducationId);
        } else {
          educationCheckedSet.delete(selectedEducationId);
        }
      }
      const educationList = document.querySelectorAll(".eduCheckbox");
      if (educationCheckedSet.size === Number(numEduSelectedPackage)) {
        educationList.forEach((edu) => {
          if (edu.checked !== true) {
            edu.disabled = true;
          }
        });
      } else {
        educationList.forEach((edu) => {
          if (edu.checked !== true) {
            edu.disabled = false;
          }
        });
      }

      var selectedEducation = document.getElementById(selectedEducationId);
      if (selectedEducation.className.indexOf("Styles.w3show") === -1) {
        selectedEducation.className += "Styles.w3show";
      } else {
        selectedEducation.className = selectedEducation.className.replace(
          "Styles.w3show",
          ""
        );
      }
      setEduFieldData(event.target.name);
    } catch (error) {
      console.error("An error occurred in handleEducation:", error);
    }
  }
  // Method for filling values into educations
  function setEduFieldData(SelectedId) {
    try {
      const currentSelectedEdu = document.getElementsByClassName(
        `${SelectedId}fields`
      );
      const selectedEducation = educationIdVsEducationDetails.get(SelectedId);

      for (let i = 0; i < currentSelectedEdu.length; i++) {
        const field = currentSelectedEdu[i];
        const fieldName = field.name;

        if (SelectedId !== "0" && selectedEducation[fieldName] !== undefined) {
          if (
            fieldName === "dateAttendedEnd" ||
            fieldName === "dateAttendedStart"
          ) {
            const dateTimeString = selectedEducation[fieldName];

            if (dateTimeString) {
              field.value = dateTimeString.split("T")[0];
            }
          } else {
            field.value = selectedEducation[fieldName];
          }
        }
      }
    } catch (error) {
      console.error("An error occurred in setEduFieldData:", error);
    }
  }

  //Event for handling changes of Services
  const handleServiceChange = (event) => {
    try {
      if (backGroundDetails.State) {
        currentStatevalue = backGroundDetails.State.trimEnd();
      } else {
        currentStatevalue = "None";
      }
      var updateArray = [];
      serviceCalloutData();
      if (event.target.value === "Package") {
        setFieldsArray([]);
        submitButtonCheckbox = false;
        statesForDrivingLicense = statesArray;
        dlStates = statesForDrivingLicense.slice(2);
        const servicesList = document.querySelectorAll(".ServicesClass");
        servicesList.forEach((checkbox) => {
          checkbox.checked = false;
          numEduSelectedPackage = 0;
          numEmpSelectedPackage = 0;
        });
        SelectedAreaSet.clear();
        SetNewSet(Array.from(SelectedAreaSet));
        setrelatedFieldsServiceArray(updateArray);
        if (event.target.checked === false) {
          const packageList = document.querySelectorAll(".packagesClass");
          submitButtonCheckbox = false;
          packageList.forEach((checkbox) => {
            checkbox.checked = false;
            checkbox.value = "packageNone";
          });
        }

        setIsCheckboxSelected1(!event.target.checked);
        setIsPackageSelected(event.target.checked);

        if (isPackageSelected === false) {
          document.getElementById("areasForSelection").className =
            styles.areaSelection;
        } else {
          document.getElementById("areasForSelection").className = "";
        }
      } else {
        setTimeout(() => {
          formDataFill();
        }, 100);
        statesForDrivingLicense = statesArray;
        dlStates = statesForDrivingLicense.slice(2);
        backGroundDetails.PackageId = "";
        if (event.target.checked) {
          selectedServiceNames.push(event.target.id);
        } else if (event.target.checked === false) {
          setrelatedFieldsServiceArray([]);
          const index = selectedServiceNames.indexOf(event.target.id);
          if (index !== -1) {
            selectedServiceNames.splice(index, 1);
          }
        }
        relatedFields();
        //Method for displaying only the unique fields from the serivices selected
        function relatedFields() {
          try {
            setrelatedFieldsServiceArray([]);
            var concatArray = [];
            var arrayUniqueByKey = [];
            selectedServiceNames.forEach((element) => {
              concatArray = concatArray.concat(
                service_RequiredFieldsMap.get(element)
              );
              let key = "Propertyname";
              arrayUniqueByKey = [
                ...new Map(
                  concatArray.map((item) => [item[key], item])
                ).values(),
              ];
              setrelatedFieldsServiceArray(arrayUniqueByKey);
              setFieldsArray(updateArray);
              backGroundDetails.Services = selectedServiceNames;
              setUpdatedbackGroundDetails(backGroundDetails);
            });
            if (arrayUniqueByKey.length > 0) {
              submitButtonCheckbox = true;
            } else if (arrayUniqueByKey.length <= 0) {
              submitButtonCheckbox = false;
            }
          } catch (error) {
            console.error("An error occurred in relatedFields:", error);
          }
        }
      }
    } catch (error) {
      console.error("An error occurred in handleServiceChange:", error);
    }
  };
  // Function for service selections
  function handleServiceSelections(event) {
    try {
      submitButtonCheckbox = event.target.checked; // Simplified assignment
    } catch (error) {
      console.error("An error occurred in handleServiceSelections:", error);
    }
  }
  // Function for handling removal of areas
  function handleRemoveArea(event) {
    try {
      const areaToRemove = event.target.innerHTML;
      const StateSelected = areaToRemove.split(" -")[0];
      SelectedAreaSet.delete(areaToRemove);
      SetNewSet(Array.from(SelectedAreaSet));

      if (stateVsAreas.has(StateSelected)) {
        const arrayRelToCurrentState = stateVsAreas.get(StateSelected);
        const index = arrayRelToCurrentState.indexOf(areaToRemove);
        if (index !== -1) {
          arrayRelToCurrentState.splice(index, 1);
          stateVsAreas.set(StateSelected, arrayRelToCurrentState);

          if (backGroundDetails.mapStateAreas !== null) {
            const obj = Object.fromEntries(stateVsAreas);
            backGroundDetails.mapStateAreas = JSON.stringify(obj);
            setUpdatedbackGroundDetails(backGroundDetails);
          }
        }
        backGroundDetails.mapStateAreas = Array.from(SelectedAreaSet);
      }
    } catch (error) {
      console.error("An error occurred in handleRemoveArea:", error);
    }
  }

  //Function for handling Selection of Areas
  function handleAreaClick(event) {
    try {
      var SelectedElement = event.target.innerHTML;

      if (stateVsAreas.has(CurrentState)) {
        var ExistingArray = stateVsAreas.get(CurrentState);
        ExistingArray.push(SelectedElement);
      } else {
        var newArrayOfAreas = [];
        newArrayOfAreas.push(SelectedElement);
        stateVsAreas.set(CurrentState, newArrayOfAreas);
      }
      if (backGroundDetails.mapStateAreas !== null) {
        const obj = Object.fromEntries(stateVsAreas);
        backGroundDetails.mapStateAreas = JSON.stringify(obj);
        setUpdatedbackGroundDetails(backGroundDetails);
      }

      SelectedAreaSet.add(CurrentState + " - " + SelectedElement);
      SetNewSet(Array.from(SelectedAreaSet));
      backGroundDetails.mapStateAreas = Array.from(SelectedAreaSet);
    } catch (error) {
      console.error("An error occurred in handleAreaClick:", error);
    }
  }

  //Method for Handling change in State
  async function handleStateChange(event) {
    try {
      CurrentState = event.target.value;
      const valuesCorrToselectedState = await statesAreaMap.get(
        event.target.value
      );
      setAreaArray(valuesCorrToselectedState);
    } catch (error) {
      console.error("An error occurred in handleStateChange:", error);
    }
  }

  //Method for Handling package Change
  function handlePackageChange(event) {
    try {
      disableEducation = false;
      disableEmployement = false;
      educationCheckedSet.clear();
      employementCheckedSet.clear();
      const EmployementList = document.querySelectorAll(".employmentCheckbox");
      EmployementList.forEach((emp) => {
        emp.disabled = false;
        if (emp.checked === true) {
          emp.checked = false;
          var selectedEmployeeDetails = document.getElementById(emp.name);
          if (
            selectedEmployeeDetails.className.indexOf("Styles.w3show") === -1
          ) {
            selectedEmployeeDetails.className += "Styles.w3show";
          } else {
            selectedEmployeeDetails.className =
              selectedEmployeeDetails.className.replace("Styles.w3show", "");
          }
        } else {
          employementCheckedSet.delete(emp);
        }
      });

      const EducationList = document.querySelectorAll(".eduCheckbox");
      EducationList.forEach((edu) => {
        edu.disabled = false;
        if (edu.checked === true) {
          edu.checked = false;
          var selectedEmployeeDetails = document.getElementById(edu.name);
          if (
            selectedEmployeeDetails.className.indexOf("Styles.w3show") === -1
          ) {
            selectedEmployeeDetails.className += "Styles.w3show";
          } else {
            selectedEmployeeDetails.className =
              selectedEmployeeDetails.className.replace("Styles.w3show", "");
          }
        } else {
          educationCheckedSet.delete(edu);
        }
      });

      numEduSelectedPackage = 0;
      numEmpSelectedPackage = 0;
      var event1 = document.getElementById("selectedPackage");
      SelectedPackage = event1.options[event1.selectedIndex].value;
      backGroundDetails.selectedPackageName = SelectedPackage;

      var selectedPackageId = event1.options[event1.selectedIndex].id;
      if (selectedPackageId) {
        setUpdatedbackGroundDetails(backGroundDetails);
        submitButtonCheckbox = true;
      } else if (selectedPackageId === "") {
        submitButtonCheckbox = false;
      }
      if (packageIdVsDataMap.get(selectedPackageId)) {
        var numberEduEmpArray = packageIdVsDataMap.get(selectedPackageId);
        numEduSelectedPackage = numberEduEmpArray[0].NumEdu;
        numEmpSelectedPackage = numberEduEmpArray[0].NumEmp;
      }
      if (selectedPackageId !== undefined && selectedPackageId !== "") {
        backGroundDetails.PackageId = selectedPackageId;
      }
      const newValue = packageIdFieldsMap.get(selectedPackageId);
      if (backGroundDetails.State) {
        currentStatevalue = backGroundDetails.State.trimEnd();
      }
      setUpdatedbackGroundDetails(backGroundDetails);
      if (newValue) {
        setFieldsArray(newValue);
      } else {
        setFieldsArray([]);
      }
      setTimeout(() => {
        formDataFill();
      }, 100);
    } catch (error) {
      console.error("An error occurred in handlePackageChange:", error);
    }
  }

  //function for filling the data into the Dynamic Form coming from callouts
  function formDataFill() {
    try {
      const checkBoxPackageFields =
        document.querySelectorAll(".checkBoxFields");
      if (checkBoxPackageFields) {
        checkBoxPackageFields.forEach((item) => {
          var PropertyName = item.id;
          if (PropertyName) {
            item.value = backGroundDetails[PropertyName];
          }
        });
      }
      const serviceFieldsList = document.querySelectorAll(".serviceinputs");
      const packageFieldsList = document.querySelectorAll(".packageForminputs");
      if (packageFieldsList) {
        packageFieldsList.forEach((element) => {
          var PropertyName = element.id;
          if (PropertyName) {
            if (backGroundDetails[PropertyName] === undefined) {
              element.value = "";
            } else {
              element.value = backGroundDetails[PropertyName];
            }
          }
        });
      } else {
        setFieldsArray([]);
      }
      if (serviceFieldsList) {
        serviceFieldsList.forEach((element) => {
          element.value = element.id;
        });
      } else {
        setFieldsArray([]);
      }
    } catch (error) {
      console.error("An error occurred in formDataFill:", error);
    }
  }

  //handleClick for input with type as checkbox
  function handleCheckbox(event) {
    try {
      if (event.target.checked) {
        backGroundDetails[event.target.id] = "1";
      } else {
        backGroundDetails[event.target.id] = "0";
      }
    } catch (error) {
      console.error("An error occurred in handleCheckbox:", error);
    }
  }
  //Function having data of fields of Employement
  function getEmploymentFields() {
    try {
      if (employementFieldArray.length === 0) {
        employementFieldArray.push({
          ApiName: "DOTVerify",
          Propertyname: "DOT Verify",
          Propertytype: "text",
          Propertyvalue: "",
        });
        employementFieldArray.push({
          ApiName: "TempAgency",
          Propertyname: "Temp Agency",
          Propertytype: "text",
          Propertyvalue: "",
        });
        employementFieldArray.push({
          ApiName: "CompComments",
          Propertyname: "Comp Comments",
          Propertytype: "text",
          Propertyvalue: "",
        });
        employementFieldArray.push({
          ApiName: "CompContact",
          Propertyname: "Comp Contact",
          Propertytype: "text",
          Propertyvalue: "",
        });
        employementFieldArray.push({
          ApiName: "phoneNumber",
          Propertyname: "Comp ContactPhone",
          Propertytype: "text",
          Propertyvalue: "",
        });
        employementFieldArray.push({
          ApiName: "employer",
          Propertyname: "Comp Name",
          Propertytype: "text",
          Propertyvalue: "",
        });
        employementFieldArray.push({
          ApiName: "employerAddressCombined",
          Propertyname: "Comp Address",
          Propertytype: "text",
          Propertyvalue: "",
        });
        employementFieldArray.push({
          ApiName: "employerAddress.municipality",
          Propertyname: "Comp City",
          Propertytype: "text",
          Propertyvalue: "",
        });
        employementFieldArray.push({
          ApiName: "employerAddress.region",
          Propertyname: "Comp State",
          Propertytype: "text",
          Propertyvalue: "",
        });
        employementFieldArray.push({
          ApiName: "employerAddress.postalCode",
          Propertyname: "Comp Zip",
          Propertytype: "text",
          Propertyvalue: "",
        });
        employementFieldArray.push({
          ApiName: "reasonForLeaving",
          Propertyname: "Comp Leave",
          Propertytype: "text",
          Propertyvalue: "",
        });
        employementFieldArray.push({
          ApiName: "startDate",
          Propertyname: "Hire Date",
          Propertytype: "date",
          Propertyvalue: "",
        });
        employementFieldArray.push({
          ApiName: "endDate",
          Propertyname: "End Date",
          Propertytype: "date",
          Propertyvalue: "",
        });
        employementFieldArray.push({
          ApiName: "position",
          Propertyname: "Comp Title",
          Propertytype: "text",
          Propertyvalue: "",
        });
        employementFieldArray.push({
          ApiName: "pay",
          Propertyname: "Comp Salary",
          Propertytype: "text",
          Propertyvalue: "",
        });
      }
    } catch (error) {
      console.error("An error occurred in getEmploymentFields:", error);
    }
  }

  // Function having data of fields of Education
  function educationFieldsData() {
    try {
      if (educationFieldsArray.length === 0) {
        educationFieldsArray.push({
          ApiName: "educationId",
          Propertyname: "School Id",
          Propertytype: "text",
          Propertyvalue: "",
        });
        educationFieldsArray.push({
          ApiName: "institutionAttended",
          Propertyname: "School Name",
          Propertytype: "text",
          Propertyvalue: "",
        });
        educationFieldsArray.push({
          ApiName: "degreeAwarded",
          Propertyname: "School Degree",
          Propertytype: "text",
          Propertyvalue: "",
        });
        educationFieldsArray.push({
          ApiName: "dateAttendedStart",
          Propertyname: "School Start Date",
          Propertytype: "date",
          Propertyvalue: "",
        });
        educationFieldsArray.push({
          ApiName: "dateAttendedEnd",
          Propertyname: "School End Date",
          Propertytype: "date",
          Propertyvalue: "",
        });
        educationFieldsArray.push({
          ApiName: "major",
          Propertyname: "School Major",
          Propertytype: "text",
          Propertyvalue: "",
        });
      }
    } catch (error) {
      console.error("An error occurred in EducationfieldData :", error);
    }
  }

  //Function having data for fields for education creation
  function educationCreationData() {
    educationCreationFieldsArray = [];
    try {
      educationCreationFieldsArray.push({
        ApiName: "institutionAttended",
        Propertyname: "Institute Name",
        Propertytype: "text",
        Propertyvalue: "",
      });
      educationCreationFieldsArray.push({
        ApiName: "includeInBackgroundChecks",
        Propertyname: "Include In BackgroundCheck",
        Propertytype: "text",
        Propertyvalue: "",
      });
      educationCreationFieldsArray.push({
        ApiName: "degreeAwarded",
        Propertyname: "Degree Awarded",
        Propertytype: "text",
        Propertyvalue: "",
      });
      educationCreationFieldsArray.push({
        ApiName: "enrollmentStatus",
        Propertyname: "Enrollment Status",
        Propertytype: "text",
        Propertyvalue: "",
      });
      educationCreationFieldsArray.push({
        ApiName: "other",
        Propertyname: "Other",
        Propertytype: "text",
        Propertyvalue: "",
      });
      educationCreationFieldsArray.push({
        ApiName: "grade",
        Propertyname: "Grade",
        Propertytype: "text",
        Propertyvalue: "",
      });
      educationCreationFieldsArray.push({
        ApiName: "dateAttendedStart",
        Propertyname: "Start Date",
        Propertytype: "text",
        Propertyvalue: "",
      });
      educationCreationFieldsArray.push({
        ApiName: "dateAttendedEnd",
        Propertyname: "End Date",
        Propertytype: "text",
        Propertyvalue: "",
      });
      educationCreationFieldsArray.push({
        ApiName: "degreeDate",
        Propertyname: "Degree Date",
        Propertytype: "text",
        Propertyvalue: "",
      });
      educationCreationFieldsArray.push({
        ApiName: "major",
        Propertyname: "Major",
        Propertytype: "text",
        Propertyvalue: "",
      });
      educationCreationFieldsArray.push({
        ApiName: "contactInformation",
        Propertyname: "Contact Information",
        Propertytype: "text",
        Propertyvalue: "",
      });
      educationCreationFieldsArray.push({
        ApiName: "street1",
        Propertyname: "Street 1",
        Propertytype: "text",
        Propertyvalue: "",
      });
      educationCreationFieldsArray.push({
        ApiName: "street2",
        Propertyname: "Street 2",
        Propertytype: "text",
        Propertyvalue: "",
      });
      educationCreationFieldsArray.push({
        ApiName: "municipality",
        Propertyname: "Municipality",
        Propertytype: "text",
        Propertyvalue: "",
      });
      educationCreationFieldsArray.push({
        ApiName: "postalCode",
        Propertyname: "Postal Code",
        Propertytype: "text",
        Propertyvalue: "",
      });
    } catch (error) {
      console.error("An error occurred in  educationCreationData:", error);
    }
  }
  //Function having DAta of fields of Different Services
  function serviceCalloutData() {
    try {
      for (let i = 0; i < services_array.length; i++) {
        servicesFieldsArray = [];
        try {
          if (
            backGroundDetails.YearsSearch !== "" ||
            backGroundDetails.YearsSearch != null ||
            backGroundDetails.YearsSearch !== undefined
          ) {
            backGroundDetails.YearsSearch = 7;
          }
          servicesFieldsArray.push({
            ApiName: "YearsSearch",
            Propertyname: "Years Search",
            Propertytype: "number",
            Propertyvalue: backGroundDetails.YearsSearch,
          });
        } catch (error) {
          console.error("Error processing YearsSearch", error);
        }
        try {
          if (services_array[i].pname === "CIA Super Search") {
            servicesFieldsArray.push({
              ApiName: "FirstName",
              Propertyname: "First Name",
              Propertytype: "text",
              Propertyvalue: backGroundDetails.FirstName,
            });

            servicesFieldsArray.push({
              ApiName: "LastName",
              Propertyname: "Last Name",
              Propertytype: "text",
              Propertyvalue: backGroundDetails.LastName,
            });
            servicesFieldsArray.push({
              ApiName: "MiddleInitial",
              Propertyname: "Middle Initial",
              Propertytype: "text",
              Propertyvalue: backGroundDetails.MiddleInitial,
            });
            servicesFieldsArray.push({
              ApiName: "DOBDAY",
              Propertyname: "DOB",
              Propertytype: "number",
              Propertyvalue: backGroundDetails.DOBDAY,
            });
          } else if (services_array[i].pname === "CIA Super Search (Alias)") {
            servicesFieldsArray.push({
              ApiName: "FirstName",
              Propertyname: "First Name",
              Propertytype: "text",
              Propertyvalue: backGroundDetails.FirstName,
            });
            servicesFieldsArray.push({
              ApiName: "LastName",
              Propertyname: "Last Name",
              Propertytype: "text",
              Propertyvalue: backGroundDetails.LastName,
            });
            servicesFieldsArray.push({
              ApiName: "MiddleInitial",
              Propertyname: "Middle Initial",
              Propertytype: "text",
              Propertyvalue: backGroundDetails.MiddleInitial,
            });
            servicesFieldsArray.push({
              ApiName: "DOBDAY",
              Propertyname: "DOB",
              Propertytype: "number",
              Propertyvalue: backGroundDetails.DOBDAY,
            });
            servicesFieldsArray.push({
              ApiName: "SSN1",
              Propertyname: "SSN ",
              Propertytype: "number",
              Propertyvalue: backGroundDetails.SSN1,
            });
          } else if (services_array[i].pname === "Criminal County Record") {
            servicesFieldsArray.push({
              ApiName: "FirstName",
              Propertyname: "First Name",
              Propertytype: "text",
              Propertyvalue: backGroundDetails.FirstName,
            });

            servicesFieldsArray.push({
              ApiName: "LastName",
              Propertyname: "Last Name",
              Propertytype: "text",
              Propertyvalue: backGroundDetails.LastName,
            });
            servicesFieldsArray.push({
              ApiName: "MiddleInitial",
              Propertyname: "Middle Initial",
              Propertytype: "text",
              Propertyvalue: backGroundDetails.MiddleInitial,
            });
            servicesFieldsArray.push({
              ApiName: "DOBDAY",
              Propertyname: "DOB",
              Propertytype: "number",
              Propertyvalue: backGroundDetails.DOBDAY,
            });
          } else if (services_array[i].pname === "Motor Vehicle Report") {
            servicesFieldsArray.push({
              ApiName: "FirstName",
              Propertyname: "First Name",
              Propertytype: "text",
              Propertyvalue: backGroundDetails.FirstName,
            });

            servicesFieldsArray.push({
              ApiName: "LastName",
              Propertyname: "Last Name",
              Propertytype: "text",
              Propertyvalue: backGroundDetails.LastName,
            });
            servicesFieldsArray.push({
              ApiName: "MiddleInitial",
              Propertyname: "Middle Initial",
              Propertytype: "text",
              Propertyvalue: backGroundDetails.MiddleInitial,
            });
            servicesFieldsArray.push({
              ApiName: "DOBDAY",
              Propertyname: "DOB",
              Propertytype: "number",
              Propertyvalue: backGroundDetails.DOBDAY,
            });
            servicesFieldsArray.push({
              ApiName: "DLNumber",
              Propertyname: "DL Number",
              Propertytype: "number",
              Propertyvalue: backGroundDetails.DLNumber,
            });
            servicesFieldsArray.push({
              ApiName: "DLState",
              Propertyname: "DL State",
              Propertytype: "text",
              Propertyvalue: backGroundDetails.DLState,
            });
          } else if (services_array[i].pname === "National Sexual Offender") {
            servicesFieldsArray.push({
              ApiName: "FirstName",
              Propertyname: "First Name",
              Propertytype: "text",
              Propertyvalue: backGroundDetails.FirstName,
            });

            servicesFieldsArray.push({
              ApiName: "LastName",
              Propertyname: "Last Name",
              Propertytype: "text",
              Propertyvalue: backGroundDetails.LastName,
            });
            servicesFieldsArray.push({
              ApiName: "MiddleInitial",
              Propertyname: "Middle Initial",
              Propertytype: "text",
              Propertyvalue: backGroundDetails.MiddleInitial,
            });
            servicesFieldsArray.push({
              ApiName: "DOBDAY",
              Propertyname: "DOB",
              Propertytype: "number",
              Propertyvalue: backGroundDetails.DOBDAY,
            });
          } else if (services_array[i].pname === "Sexual Offender Registry") {
            Array.from(educationIdVsEducationDetails.entries());
            servicesFieldsArray.push({
              ApiName: "FirstName",
              Propertyname: "First Name",
              Propertytype: "text",
              Propertyvalue: backGroundDetails.FirstName,
            });

            servicesFieldsArray.push({
              ApiName: "LastName",
              Propertyname: "Last Name",
              Propertytype: "text",
              Propertyvalue: backGroundDetails.LastName,
            });
            servicesFieldsArray.push({
              ApiName: "MiddleInitial",
              Propertyname: "Middle Initial",
              Propertytype: "text",
              Propertyvalue: backGroundDetails.MiddleInitial,
            });
            servicesFieldsArray.push({
              ApiName: "DOBDAY",
              Propertyname: "DOB",
              Propertytype: "number",
              Propertyvalue: backGroundDetails.DOBDAY,
            });
            servicesFieldsArray.push({
              ApiName: "State",
              Propertyname: "State",
              Propertytype: "text",
              Propertyvalue: backGroundDetails.State,
            });
          } else if (services_array[i].pname === "Social Security Trace") {
            servicesFieldsArray.push({
              ApiName: "FirstName",
              Propertyname: "First Name",
              Propertytype: "text",
              Propertyvalue: backGroundDetails.FirstName,
            });

            servicesFieldsArray.push({
              ApiName: "LastName",
              Propertyname: "Last Name",
              Propertytype: "text",
              Propertyvalue: backGroundDetails.LastName,
            });
            servicesFieldsArray.push({
              ApiName: "MiddleInitial",
              Propertyname: "Middle Initial",
              Propertytype: "text",
              Propertyvalue: backGroundDetails.MiddleInitial,
            });
            servicesFieldsArray.push({
              ApiName: "DOBDAY",
              Propertyname: "DOB",
              Propertytype: "number",
              Propertyvalue: backGroundDetails.DOBDAY,
            });
          } else if (services_array[i].pname === "CIA Super Search (Alias)") {
            servicesFieldsArray.push({
              ApiName: "FirstName",
              Propertyname: "First Name",
              Propertytype: "text",
              Propertyvalue: backGroundDetails.FirstName,
            });

            servicesFieldsArray.push({
              ApiName: "LastName",
              Propertyname: "Last Name",
              Propertytype: "text",
              Propertyvalue: backGroundDetails.LastName,
            });
            servicesFieldsArray.push({
              ApiName: "MiddleInitial",
              Propertyname: "Middle Initial",
              Propertytype: "text",
              Propertyvalue: backGroundDetails.MiddleInitial,
            });
            servicesFieldsArray.push({
              ApiName: "DOBDAY",
              Propertyname: "DOB",
              Propertytype: "number",
              Propertyvalue: backGroundDetails.DOBDAY,
            });
            servicesFieldsArray.push({
              ApiName: "SSN1",
              Propertyname: "SSN",
              Propertytype: "number",
              Propertyvalue: backGroundDetails.SSN1,
            });
          }
        } catch (error) {
          console.error(
            "Error processing fields for service:",
            services_array[i].pname,
            error
          );
        }
        try {
          service_RequiredFieldsMap.set(
            services_array[i].pname,
            servicesFieldsArray
          );
        } catch (error) {
          console.error(
            "Error setting service_RequiredFieldsMap for service:",
            services_array[i].pname,
            error
          );
        }
      }
    } catch (error) {
      console.error("Error in serviceCalloutData", error);
    }
  }

  useEffect(() => {}, [AreaArray]);

  //Method for handling Education Change
  function handleEducationChange(event) {
    try {
      var key = event.target.name;
      var selectedEducationId = event.target.id;
      if (selectedEducationId !== "0") {
        educationIdVsEducationDetails.get(selectedEducationId)[key] =
          event.target.value;
      } else {
        eduDetailsForEducationCreation.get(selectedEducationId)[key] =
          event.target.value;
      }
    } catch (error) {
      console.error("Error updating education details:", error);
    }
  }
  //Method for handling workHistory change
  function handleWorkChange(event) {
    try {
      var key = event.target.name;
      var selectedEmployementId = event.target.id;
      WorkHistoryIdVsWorkHistoryDetails.get(selectedEmployementId)[key] =
        event.target.value;
    } catch (error) {
      console.error("Error updating work history details:", error);
    }
  }

  //Method for creation of body for Employement and Education Callout
  function createBody() {
    try {
      var eduServiceBody = "";
      var empServiceBody = "";
      const empCheckedArray = Array.from(employementCheckedSet);
      if (empCheckedArray.length) {
        try {
          for (let i = 0; i < empCheckedArray.length; i++) {
            empServiceBody =
              empServiceBody +
              "<Search>" +
              "<Type>Employment</Type>" +
              "<EmpDetails>" +
              "<DOTVerify></DOTVerify>" +
              "<CompName>" +
              WorkHistoryIdVsWorkHistoryDetails.get(empCheckedArray[i])
                .employer +
              "</CompName>" +
              "<TempAgency></TempAgency>" +
              "<CompAddress>" +
              WorkHistoryIdVsWorkHistoryDetails.get(empCheckedArray[i])
                .employerAddressCombined +
              "</CompAddress>" +
              "<CompCity>" +
              WorkHistoryIdVsWorkHistoryDetails.get(empCheckedArray[i])
                .employerAddress.municipality +
              "</CompCity>" +
              "<CompState>" +
              WorkHistoryIdVsWorkHistoryDetails.get(empCheckedArray[i])
                .employerAddress.region +
              "</CompState>" +
              "<CompZip>" +
              WorkHistoryIdVsWorkHistoryDetails.get(empCheckedArray[i])
                .employerAddress.postalCode +
              "</CompZip>" +
              "<CompLeave>" +
              WorkHistoryIdVsWorkHistoryDetails.get(empCheckedArray[i])
                .reasonForLeaving +
              "</CompLeave>" +
              "<HireDate>" +
              WorkHistoryIdVsWorkHistoryDetails.get(empCheckedArray[i])
                .startDate +
              "</HireDate>" +
              "<EndDate>" +
              WorkHistoryIdVsWorkHistoryDetails.get(empCheckedArray[i])
                .endDate +
              "</EndDate>" +
              "<CompTitle>" +
              WorkHistoryIdVsWorkHistoryDetails.get(empCheckedArray[i])
                .position +
              "</CompTitle>" +
              "<CompSalary>" +
              WorkHistoryIdVsWorkHistoryDetails.get(empCheckedArray[i]).pay +
              "</CompSalary>" +
              "<CompContact>John Doe</CompContact>" +
              "<CompContactPhone>" +
              WorkHistoryIdVsWorkHistoryDetails.get(empCheckedArray[i])
                .phoneNumber +
              "</CompContactPhone>" +
              "<CompComments>Comments go here</CompComments>" +
              "</EmpDetails>" +
              "<RefNumber>EDU-REF</RefNumber>" +
              "</Search>";
          }
        } catch (error) {
          console.error("Error processing employment data:", error);
        }
      }

      const eduCheckedArray = Array.from(educationCheckedSet);
      if (eduCheckedArray.length) {
        try {
          for (let i = 0; i < eduCheckedArray.length; i++) {
            eduServiceBody =
              eduServiceBody +
              "<Search>" +
              "<Type>Education</Type>" +
              "<EduDetails>" +
              "<SchName>" +
              educationIdVsEducationDetails.get(eduCheckedArray[i])
                .institutionAttended +
              "</SchName>" +
              "<SchAddress></SchAddress>" +
              "<SchCity></SchCity>" +
              "<SchState></SchState>" +
              "<SchZip></SchZip>" +
              "<SchStartDate>" +
              educationIdVsEducationDetails.get(eduCheckedArray[i])
                .dateAttendedStart +
              "</SchStartDate>" +
              "<SchEndDate>" +
              educationIdVsEducationDetails.get(eduCheckedArray[i])
                .dateAttendedEnd +
              "</SchEndDate>" +
              "<SchMajor>" +
              educationIdVsEducationDetails.get(eduCheckedArray[i]).major +
              "</SchMajor>" +
              "<SchDegree>" +
              educationIdVsEducationDetails.get(eduCheckedArray[i])
                .degreeAwarded +
              "</SchDegree>" +
              "<SchID>" +
              educationIdVsEducationDetails.get(eduCheckedArray[i])
                .educationId +
              "</SchID>" +
              "<SchComments>" +
              educationIdVsEducationDetails.get(eduCheckedArray[i]).other +
              "</SchComments>" +
              "</EduDetails>" +
              "<RefNumber>EDU-REF</RefNumber>" +
              "</Search>";
          }
        } catch (error) {
          console.error("Error processing education data:", error);
        }
      }
      if (eduCheckedArray.length !== 0 && empCheckedArray.length === 0) {
        return eduServiceBody;
      } else if (eduCheckedArray.length === 0 && empCheckedArray.length !== 0) {
        return empServiceBody;
      } else if (eduCheckedArray.length !== 0 && empCheckedArray.length !== 0) {
        return eduServiceBody + empServiceBody;
      } else {
        return null;
      }
    } catch (error) {
      console.log(
        "Error processing body for education and Employement selected:",
        error
      );
    }
  }
  //method for handling Education creation
  const handleEduCreation = async (event) => {
    event.preventDefault();
    setLoading(true);
    const finalBody = Array.from(
      eduDetailsForEducationCreation.entries()
    ).reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});
    try {
      await axios
        .post(`${process.env.REACT_APP_API_URL}/api/eduCreationDetails`, {
          finalBody,
        })
        .then((response) => response)
        .then((data) => {
          let dataEdu = data.data.response;
          var parsedEduDetails = JSON.parse(
            data.data.stringifiededucation_DetailsMap
          );
          var newMapEduDetails = new Map(parsedEduDetails);
          seteducationIdVsEducationDetails(newMapEduDetails);
          if (typeof dataEdu == "string") {
            educationId = JSON.parse(dataEdu).educationId;
          } else {
            educationId = dataEdu.educationId;
          }
          errorResponse = data.data.Error;
        });
    } catch (event) {
      console.log("Error in sending data for Education in api", event);
    }
    setLoading(false);
    const eduObj = {
      dateAttendedEnd: "",
      dateAttendedStart: "",
      degreeAwarded: "",
      degreeDate: "",
      educationId: "",
      grade: "",
      institutionAttended: "",
      major: "",
      other: "",
    };
    var newMapEducationAddon = new Map();
    newMapEducationAddon.set("0", eduObj);

    seteduDetailsForEducationCreation(newMapEducationAddon);
    handleModalClose();
    toastEducation();
  };

  // Method for handling submit Button of Form
  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    var finalBodyEduEmp = createBody();

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/data`, {
        updatedbackGroundDetails,
        finalBodyEduEmp,
      });
      finalStatus = response.data.Status;
      ErrorResponse = response.data.Error;
    } catch (error) {
      console.log("Error", error);
    } finally {
      setLoading(false);
      console.log("Status", finalStatus);
      console.log("Error Response", ErrorResponse);
      toastMethod();
    }
  };

  //Method to handle the changes madde in the form in UI when package is selected
  function handleValueChangeInForm(event) {
    try {
      backGroundDetails[event.target.id] = event.target.value;
      setUpdatedbackGroundDetails(backGroundDetails);
    } catch (error) {
      console.log("Error handling value changes in Form:", error);
    }
  }
  //Method to handle the change made in the form in UI when Service is selected
  function handleServiceValueChange(event) {
    try {
      backGroundDetails.Type = event.target.value;
      backGroundDetails[event.target.name] = event.target.value;
      setUpdatedbackGroundDetails(backGroundDetails);
    } catch (error) {
      console.log("Error handling service value change:", error);
    }
  }

  function handleStateChangeBgCheck(event) {
    try {
      if (event.target.value === "stateNone") {
        backGroundDetails.State = "";
      } else {
        backGroundDetails.State = event.target.value;
      }
    } catch (error) {
      console.log("Error during handling State Change :", error);
    }
  }

  function handleDlStateChange(event) {
    try {
      if (event.target.value === "dlStateNone") {
        backGroundDetails.DLState = "";
      } else {
        backGroundDetails.DLState = event.target.value;
      }
    } catch (error) {
      console.log("Error while handling DL state change: ", error);
    }
  }
  //Services Array from the backend
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/services_array`);
        const data = await response.json();
        setService(data);
      } catch (error) {
        console.error("Error fetching data Service Array:", error);
      }
    };
    fetchData();
  }, []);

  // Array containing PackageName and ID From backend
  useEffect(() => {
    const fetchPackageData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/packageNameId`);
        const data = await response.json();
        setPackages(data);
      } catch (error) {
        console.error("Error fetching data package:", error);
      }
    };

    fetchPackageData();
  }, []);

  // When the user clicks the button, open the modal
  function handleModal(event) {
    try {
      var modal = document.getElementById("myModal");
      modal.style.display = "block";
    } catch (error) {
      console.log(
        "Error in handling opening of Modal for  New Education: ",
        error
      );
    }
  }

  function handleCrossClickModal(event) {
    try {
      var modal = document.getElementById("myModal");
      modal.style.display = "none";

      const eduObj = {
        dateAttendedEnd: "",
        dateAttendedStart: "",
        degreeAwarded: "",
        degreeDate: "",
        educationId: "",
        grade: "",
        institutionAttended: "",
        major: "",
        other: "",
      };

      var newMapEducationAddon = new Map();
      newMapEducationAddon.set("0", eduObj);
      seteduDetailsForEducationCreation(newMapEducationAddon);
      var currentSelectedEdu = document.getElementsByClassName("0fields");
      for (let i = 0; i < currentSelectedEdu.length; i++) {
        currentSelectedEdu[i].value = "";
      }
    } catch (error) {
      console.log("Error in handling cross of modal: ", error);
    }
  }

  useEffect(() => {}, [eduDetailsForEducationCreation]);

  // When the user clicks on <span> (x), close the modal
  function handleModalClose(event) {
    try {
      var modal = document.getElementById("myModal");
      modal.style.display = "none";

      const eduObj = {
        dateAttendedEnd: "",
        dateAttendedStart: "",
        degreeAwarded: "",
        degreeDate: "",
        educationId: "",
        grade: "",
        institutionAttended: "",
        major: "",
        other: "",
      };
      var newMapEducationAddon = new Map();
      newMapEducationAddon.set("0", eduObj);
      seteduDetailsForEducationCreation(newMapEducationAddon);
      var currentSelectedEdu = document.getElementsByClassName("0fields");
      for (let i = 0; i < currentSelectedEdu.length; i++) {
        currentSelectedEdu[i].value = "";
      }
    } catch (error) {
      console.log("Error in Modal for new Education :", error);
    }
  }

  return (
    <div className={styles.App}>
      <div>{loading ? <Spinner /> : <div></div>}</div>

      <div className={styles.indentation}>
        <div>
          <label forhtml="service-package">SELECT SERVICE(S)*</label>
          <div className={styles.box}>
            <div>
              <input
                type="checkbox"
                id={`service-package`}
                name="service"
                value="Package"
                onChange={handleServiceChange}
              />
              <label htmlFor={`service-package`}>Package</label>
            </div>
            {services_array.map((service, index) => (
              <div>
                <input
                  type="checkbox"
                  id={service.pname}
                  name="service"
                  className="ServicesClass"
                  // value={service.pname}
                  onChange={handleServiceChange}
                  disabled={isPackageSelected}
                  onClick={handleServiceSelections}
                />
                <label htmlFor={`service-${index}`}>{service.pname}</label>
              </div>
            ))}
          </div>
        </div>
        <div className={classNames(styles.gridContainer, "stateOptions")}>
          <label forhtml="stateDropdown">Select State:</label>
          <select
            name="States"
            disabled={isPackageSelected}
            onChange={handleStateChange}
            className={styles.SelectBox}
            id="stateDropdown"
          >
            {statesArray.map((state, index) => (
              <option value={state.Name} className={styles.states}>
                {state.Name}
              </option>
            ))}
          </select>
        </div>
        <br></br>
        <br></br>
        <h4 className={styles.addArea}>Click to add</h4>
        <div className={styles.AreaBlock} id={styles.addArea}>
          <div id="areasForSelection">
            {(() => {
              if (AreaArray) {
                return AreaArray.map((area, index) => (
                  <div key={index} className={styles.selectArea}>
                    <div onClick={handleAreaClick}>{area.Area}</div>
                  </div>
                ));
              }
            })()}
          </div>
        </div>
        <h4 className={styles.removeArea}>Click To Remove</h4>
        <div className={styles.AreaBlock}>
          {(() => {
            if (newSet) {
              return Array.from(newSet).map((area, index) => (
                <div
                  disabled
                  key={index}
                  onClick={handleRemoveArea}
                  className={styles.selectArea}
                >
                  {area}
                </div>
              ));
            }
          })()}
        </div>
      </div>
      <div>
        <h4 className={styles.heading}>Select package:</h4>

        <select
          name="Package"
          className="packagesClass"
          disabled={isCheckboxSelected1}
          onChange={handlePackageChange}
          id="selectedPackage"
        >
          <option value="packageNone" selected>
            -- None --
          </option>
          {package_array.map((pkg, index) => (
            <option
              value={pkg.name}
              className={styles.states}
              id={pkg.Id}
              onClick={handleServiceSelections}
            >
              {pkg.name}
            </option>
          ))}
        </select>
      </div>

      <div className={styles.dynamicForm}>
        <form onSubmit={handleSubmit} id="FORM1">
          <div className={styles.property}>
            {(() => {
              if (relatedFieldsArray && isPackageSelected === true) {
                return relatedFieldsArray.map((element, index) => (
                  <div key={index} className={styles.formElements}>
                    {(() => {
                      if (element.PropertyName === "SSN1") {
                        return (
                          <label
                            htmlFor={element.ApiName}
                            size="20"
                            className={styles.fieldLabel}
                          >
                            SSN *
                          </label>
                        );
                      } else if (element.PropertyName === "DOBDAY") {
                        return (
                          <label
                            htmlFor={element.ApiName}
                            size="20"
                            className={styles.fieldLabel}
                          >
                            DOB *
                          </label>
                        );
                      } else if (
                        !element.PropertyName.includes("SSN") &&
                        !element.PropertyName.includes("CompName") &&
                        !element.PropertyName.includes("CompCity_1") &&
                        !element.PropertyName.includes("CompState_1") &&
                        element.Required === true
                      ) {
                        return (
                          <label
                            htmlFor={element.ApiName}
                            size="20"
                            className={styles.fieldLabel}
                          >
                            {element.ApiName} *
                          </label>
                        );
                      } else if (
                        element.PropertyName.includes("AdditionalInfo")
                      ) {
                        return (
                          <label
                            htmlFor={element.ApiName}
                            size="20"
                            className={styles.fieldLabel}
                          >
                            Additional Info
                          </label>
                        );
                      } else {
                        return (
                          <label
                            htmlFor={element.ApiName}
                            size="20"
                            className={styles.fieldLabel}
                          >
                            {element.ApiName}
                          </label>
                        );
                      }
                    })()}
                    {(() => {
                      if (element.PropertyName.includes("SSN1")) {
                        return (
                          <nobr>
                            <input
                              type="text"
                              className="packageForminputs"
                              // placeholder="SSN1"
                              maxLength="3"
                              onChange={handleValueChangeInForm}
                              id="SSN1"
                              name="SSN1"
                              style={{ width: "40px" }}
                              required
                            />

                            <input
                              type="text"
                              className="packageForminputs"
                              // placeholder="SSN2"
                              maxLength="2"
                              onChange={handleValueChangeInForm}
                              // className="packageForminputs"
                              // value={element.value}
                              style={{ width: "40px" }}
                              id="SSN2"
                              name="SSN2"
                              required
                            />

                            <input
                              type="text"
                              className="packageForminputs"
                              // placeholder="SSN3"
                              maxLength="4"
                              onChange={handleValueChangeInForm}
                              // className="packageForminputs"
                              // value={element.value}
                              style={{ width: "40px" }}
                              id="SSN3"
                              name="SSN3"
                              required
                            />
                          </nobr>
                        );
                      } else if (element.PropertyName.includes("DOBDAY")) {
                        return (
                          <nobr>
                            <input
                              type="text"
                              className="packageForminputs"
                              // placeholder="SSN1"
                              maxLength="2"
                              onChange={handleValueChangeInForm}
                              id="DOBDAY"
                              name="DOBDAY"
                              style={{ width: "40px" }}
                              required
                            />

                            <input
                              type="text"
                              className="packageForminputs"
                              maxLength="2"
                              onChange={handleValueChangeInForm}
                              style={{ width: "40px" }}
                              id="DOBMONTH"
                              name="DOBMONTH"
                              required
                            />

                            <input
                              type="text"
                              className="packageForminputs"
                              maxLength="4"
                              onChange={handleValueChangeInForm}
                              style={{ width: "40px" }}
                              id="DOBYEAR"
                              name="DOBYEAR"
                              required
                            />
                          </nobr>
                        );
                      } else if (element.ApiName === "State") {
                        return (
                          <select
                            name="States"
                            onChange={handleStateChangeBgCheck}
                          >
                            <option value="stateNone">-- None --</option>
                            {statesArray.map((state, index) => (
                              <option
                                selected={state.Name === currentStatevalue}
                              >
                                {state.Name}{" "}
                              </option>
                            ))}
                          </select>
                        );
                      } else if (element.PropertyName === "DLState") {
                        return (
                          <select name="States" onChange={handleDlStateChange}>
                            <option value="dlStateNone">-- None --</option>
                            {dlStates.map((state, index) => (
                              <option
                                selected={
                                  state.Name === backGroundDetails.DLState
                                }
                              >
                                {state.Name}{" "}
                              </option>
                            ))}
                          </select>
                        );
                      } else if (
                        element.DataType === "checkbox" &&
                        element.Required === false
                      ) {
                        return (
                          <input
                            size="20"
                            className="packageForminputs"
                            // value={element.value}
                            type={element.DataType}
                            id={element.PropertyName}
                            name={element.ApiName}
                            onClick={handleCheckbox}
                          />
                        );
                      } else if (
                        element.DataType === "checkbox" &&
                        element.Required === false
                      ) {
                        return (
                          <input
                            size="20"
                            className="packageForminputs"
                            // value={element.value}
                            type={element.DataType}
                            id={element.PropertyName}
                            name={element.ApiName}
                            onClick={handleCheckbox}
                          />
                        );
                      } else if (
                        element.Required === false &&
                        !element.PropertyName.includes("SSN")
                      ) {
                        return (
                          <input
                            size="20"
                            className="packageForminputs"
                            // value={element.value}N
                            type={element.DataType}
                            id={element.PropertyName}
                            name={element.ApiName}
                            onChange={handleValueChangeInForm}
                          />
                        );
                      } else if (
                        element.PropertyName.includes("CompName") ||
                        element.PropertyName.includes("CompCity_1") ||
                        element.PropertyName.includes("CompState_1")
                      ) {
                        return (
                          <input
                            size="20"
                            className="packageForminputs"
                            // value={element.value}
                            type={element.DataType}
                            id={element.PropertyName}
                            name={element.ApiName}
                            onChange={handleValueChangeInForm}
                          />
                        );
                      } else if (
                        !element.PropertyName.includes("SSN") &&
                        !element.PropertyName.includes("CompName") &&
                        !element.PropertyName.includes("CompCity_1") &&
                        !element.PropertyName.includes("CompState_1")
                      ) {
                        return (
                          <input
                            size="20"
                            className="packageForminputs"
                            // value={element.value}
                            type={element.DataType}
                            id={element.PropertyName}
                            name={element.ApiName}
                            onChange={handleValueChangeInForm}
                            required
                          />
                        );
                      }
                    })()}
                  </div>
                ));
              } else if (
                relatedFieldsServiceArray &&
                isPackageSelected === false
              ) {
                return relatedFieldsServiceArray.map((service, index) => (
                  <div key={index} className={styles.formElements}>
                    <label
                      htmlFor={service.Propertyvalue}
                      size="20"
                      className={styles.fieldLabel}
                    >
                      {!service.ApiName.includes("MiddleInitial")
                        ? `${service.Propertyname} *`
                        : service.Propertyname}
                    </label>

                    {(() => {
                      if (service.ApiName.includes("SSN1")) {
                        return (
                          <nobr>
                            <input
                              type="text"
                              className="packageForminputs"
                              // placeholder="SSN1"
                              maxLength="3"
                              onChange={handleValueChangeInForm}
                              id="SSN1"
                              name="SSN1"
                              style={{ width: "40px" }}
                              required
                            />

                            <input
                              type="text"
                              className="packageForminputs"
                              // placeholder="SSN2"
                              maxLength="2"
                              onChange={handleValueChangeInForm}
                              // className="packageForminputs"
                              // value={element.value}
                              style={{ width: "40px" }}
                              id="SSN2"
                              name="SSN2"
                              required
                            />

                            <input
                              type="text"
                              className="packageForminputs"
                              // placeholder="SSN3"
                              maxLength="4"
                              onChange={handleValueChangeInForm}
                              // className="packageForminputs"
                              // value={element.value}
                              style={{ width: "40px" }}
                              id="SSN3"
                              name="SSN3"
                              required
                            />
                          </nobr>
                        );
                      } else if (service.ApiName === "DOBDAY") {
                        return (
                          <nobr>
                            <input
                              type="text"
                              className="packageForminputs"
                              // placeholder="DOB Day"
                              // value=""
                              maxLength="2"
                              onChange={handleValueChangeInForm}
                              id="DOBDAY"
                              name="DOBDAY"
                              style={{ width: "40px" }}
                              required
                            />

                            <input
                              type="text"
                              className="packageForminputs"
                              // placeholder="SSN2"
                              maxLength="2"
                              onChange={handleValueChangeInForm}
                              // className="packageForminputs"
                              // value={element.value}
                              style={{ width: "40px" }}
                              id="DOBMONTH"
                              name="DOBMONTH"
                              required
                            />

                            <input
                              type="text"
                              className="packageForminputs"
                              // placeholder="SSN3"
                              maxLength="4"
                              onChange={handleValueChangeInForm}
                              // className="packageForminputs"
                              // value={element.value}
                              style={{ width: "40px" }}
                              id="DOBYEAR"
                              name="DOBYEAR"
                              required
                            />
                          </nobr>
                        );
                      } else if (service.ApiName === "YearsSearch") {
                        return (
                          <input
                            size="20"
                            className="serviceinputs"
                            name={service.ApiName}
                            type={service.Propertytype}
                            id={service.Propertyvalue}
                            onChange={handleServiceValueChange}
                            min="7"
                            max="30"
                            required
                          />
                        );
                      } else if (service.ApiName === "State") {
                        return (
                          <select
                            name="States"
                            onChange={handleStateChangeBgCheck}
                          >
                            <option value="stateNone">-- None --</option>
                            {statesArray.map((state, index) => (
                              <option
                                selected={state.Name === currentStatevalue}
                              >
                                {state.Name}{" "}
                              </option>
                            ))}
                            required
                          </select>
                        );
                      } else if (service.ApiName === "DLState") {
                        return (
                          <select name="States" onChange={handleDlStateChange}>
                            <option value="dlStateNone">-- None --</option>
                            {dlStates.map((state, index) => (
                              <option
                                selected={
                                  state.Name === backGroundDetails.DLState
                                }
                              >
                                {state.Name}{" "}
                              </option>
                            ))}
                            required
                          </select>
                        );
                      } else if (service.ApiName === "MiddleInitial") {
                        return (
                          <input
                            size="20"
                            className="serviceinputs"
                            name={service.ApiName}
                            type={service.Propertytype}
                            id={service.Propertyvalue}
                            onChange={handleServiceValueChange}
                          />
                        );
                      } else {
                        return (
                          <input
                            size="20"
                            className="serviceinputs"
                            name={service.ApiName}
                            type={service.Propertytype}
                            id={service.Propertyvalue}
                            onChange={handleServiceValueChange}
                            required
                          />
                        );
                      }
                    })()}
                  </div>
                ));
              }
            })()}
          </div>
          <div className={classNames(styles.EduEmpBox, styles.dynamicForm)}>
            <div className={styles.eduEmpBoxinner}>
              {numEduSelectedPackage > 0 && (
                <>
                  <strong>Education</strong>
                  <h5>You can select upto {numEduSelectedPackage} Education</h5>
                  {educationIdVsEducationDetails &&
                    Array.from(educationIdVsEducationDetails.entries()).map(
                      ([key, value]) => (
                        <div key={key}>
                          <input
                            type="checkbox"
                            name={key}
                            onClick={handleEducation}
                            className="eduCheckbox"
                            disabled={disableEducation}
                          />
                          <label htmlFor={key}>{value.degreeAwarded}</label>

                          <div
                            id={key}
                            className={classNames(
                              styles.w3container,
                              styles.w3hide
                            )}
                          >
                            <form onSubmit={handleSubmit} id="FORM1">
                              <div className={styles.property}>
                                {educationFieldsArray.map(
                                  (education, index) => (
                                    <div key={index}>
                                      <label
                                        htmlFor={key}
                                        size="20"
                                        className={styles.fieldLabel}
                                      >
                                        {education.Propertyname}
                                      </label>
                                      <input
                                        size="20"
                                        name={education.ApiName}
                                        type={education.Propertytype}
                                        id={key}
                                        className={classNames(
                                          `eduData`,
                                          `${key}fields`
                                        )}
                                        onChange={handleEducationChange}
                                        required
                                      />
                                    </div>
                                  )
                                )}
                              </div>
                            </form>
                          </div>
                        </div>
                      )
                    )}
                  {Array.from(eduDetailsForEducationCreation.entries()).map(
                    ([key, value]) => (
                      <div key={key}>
                        <button type="button" id="myBtn" onClick={handleModal}>
                          Add Education
                        </button>
                      </div>
                    )
                  )}
                  <div
                    className={classNames(styles.w3container, styles.w3hide)}
                  >
                    <form onSubmit={handleSubmit} id="FORM1">
                      <div className={styles.property}>
                        {(() => {
                          if (educationCreationFieldsArray.length !== 0) {
                            return educationCreationFieldsArray.map(
                              (education, index) => (
                                <div key={index}>
                                  <label
                                    size="20"
                                    className={styles.fieldLabel}
                                  >
                                    {education.Propertyname}
                                  </label>
                                  <input
                                    id={index}
                                    size="20"
                                    name={education.ApiName}
                                    type={education.Propertytype}
                                    required
                                  />
                                </div>
                              )
                            );
                          } else {
                            <h1>Error</h1>;
                          }
                          return;
                        })()}
                      </div>
                    </form>
                  </div>
                </>
              )}
            </div>
            <div className={styles.eduEmpBoxinner}>
              {numEmpSelectedPackage > 0 && (
                <>
                  <strong> Employement</strong>
                  <h5>
                    You can select upto {numEmpSelectedPackage} Employment
                  </h5>
                  {(() => {
                    if (WorkHistoryIdVsWorkHistoryDetails) {
                      return Array.from(
                        WorkHistoryIdVsWorkHistoryDetails.entries()
                      ).map(([key, value]) => (
                        <div key={key}>
                          <input
                            type="checkbox"
                            name={key}
                            className="employmentCheckbox"
                            disabled={disableEmployement}
                            onClick={handleWorkHistory}
                          ></input>
                          <label htmlFor={key}>{value.position}</label>
                          <div
                            id={key}
                            className={classNames(
                              styles.w3container,
                              styles.w3hide
                            )}
                          >
                            <form onSubmit={handleSubmit} id="FORM1">
                              <div className={styles.property}>
                                {(() => {
                                  return employementFieldArray.map(
                                    (employement, index) => (
                                      <div key={index}>
                                        <label
                                          htmlFor={key}
                                          size="20"
                                          className={styles.fieldLabel}
                                        >
                                          {employement.Propertyname}
                                        </label>
                                        <input
                                          size="20"
                                          name={employement.ApiName}
                                          type={employement.Propertytype}
                                          id={key}
                                          className={classNames(
                                            `empData`,
                                            `${key}fields`
                                          )}
                                          onChange={handleWorkChange}
                                          required
                                        />
                                      </div>
                                    )
                                  );
                                })()}
                              </div>
                            </form>
                          </div>
                        </div>
                      ));
                    }
                  })()}
                </>
              )}
            </div>
          </div>{" "}
          {(() => {
            if (submitButtonCheckbox === true) {
              return (
                <input
                  type="submit"
                  value="Submit"
                  className={styles.submit}
                  form="FORM1"
                ></input>
              );
            } else if (submitButtonCheckbox === false) {
              return (
                <input
                  type="submit"
                  value="Submit"
                  className={styles.submit}
                  form="FORM1"
                  disabled
                ></input>
              );
            }
          })()}
        </form>
      </div>

      <ToastContainer />
      {Array.from(eduDetailsForEducationCreation.entries()).map(
        ([key, value]) => (
          <div key={key}>
            <div
              id="myModal"
              className={styles.modal}
              style={{ display: "none" }}
            >
              <div className={styles.modalcontent}>
                <form onSubmit={handleEduCreation} id="FORM2">
                  <div>
                    <span
                      className={styles.close}
                      onClick={handleCrossClickModal}
                    >
                      &times;
                    </span>
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "20px",
                        fontWeight: "bold",
                      }}
                    >
                      Create New Education
                    </p>
                  </div>
                  <hr></hr>
                  <div className={styles.property}>
                    {educationFieldsArray.map((education, index) => (
                      <div key={index}>
                        <label
                          htmlFor={key}
                          size="20"
                          className={styles.fieldLabel}
                        >
                          {education.Propertyname}
                        </label>
                        <input
                          size="20"
                          name={education.ApiName}
                          type={education.Propertytype}
                          id={key}
                          className={classNames(`eduData`, `${key}fields`)}
                          onChange={handleEducationChange}
                          required
                        />
                      </div>
                    ))}
                  </div>
                  <hr></hr>
                  <div style={{ textAlign: "center" }}>
                    <input
                      type="submit"
                      value="Create Education"
                      form="FORM2"
                      className={styles.createEducation}
                      // onClick={handleEduCreation}
                    ></input>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
}
export default App;
